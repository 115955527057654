/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.CRO_generic_color .purchase-feed-banner .feed-text-count {
  color: #333333 !important;
}

.purchase-feed-banner {
  margin: 22px auto;
}
@media (min-width: 544px) {
  .purchase-feed-banner {
    margin: 70px auto;
  }
}
.purchase-feed-banner .feed-left-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.purchase-feed-banner .feed-text-svg {
  max-width: 272px;
  height: auto;
  margin-bottom: 10px;
}
@media (max-width: 768.98px) {
  .purchase-feed-banner .feed-text-svg {
    margin-top: 22px;
  }
}
@media (min-width: 544px) {
  .purchase-feed-banner .feed-text-svg {
    max-width: 435px;
  }
}
.purchase-feed-banner p {
  font-weight: bold;
}
@media (max-width: 768.98px) {
  .purchase-feed-banner p {
    font-size: 14px;
  }
}
.purchase-feed-banner .feed-text-over {
  margin-top: 10px;
  margin-bottom: 5px;
}
.purchase-feed-banner .feed-text-over-shoplc {
  margin-bottom: 24px;
}
@media (max-width: 768.98px) {
  .purchase-feed-banner .feed-text-over-shoplc {
    margin-bottom: 0;
  }
}
.purchase-feed-banner .feed-text-count {
  color: #D93C50;
  font-size: 32px;
  line-height: 48px;
}
@media (min-width: 544px) {
  .purchase-feed-banner .feed-text-count {
    font-size: 45px;
    line-height: 53px;
  }
}
.purchase-feed-banner .feed-img-mobile {
  margin-top: 12px;
}
@media (max-width: 768.98px) {
  .purchase-feed-banner .feed-img-mobile {
    padding-left: 11px;
    padding-right: 11px;
  }
}
.purchase-feed-banner .feed-link {
  font-size: 16px;
  background-color: #1e1e2b;
  color: #fff;
  display: inline-block;
  width: 132px;
  height: 38px;
  line-height: 38px;
  margin-top: 17px;
  text-decoration: none;
  border-radius: 5px;
}
@media (min-width: 544px) {
  .purchase-feed-banner .feed-link {
    width: 271px;
    height: 50px;
    line-height: 50px;
    margin-top: 35px;
  }
}